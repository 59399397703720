import { Injectable } from '@angular/core';
import { ConsoleUser } from '@helpers/types/user';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserHelperService {
  private dialogOpen: Subject<ConsoleUser> = new Subject();

  constructor() {}

  openUserDialog(user: ConsoleUser): void {
    this.dialogOpen.next(user);
  }

  listenForOpenDialog(): Observable<ConsoleUser> {
    return new Observable<ConsoleUser>((observer) => {
      this.dialogOpen.subscribe((response) => {
        observer.next(response);
      });
    });
  }
}
