import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Data, NavigationEnd } from '@angular/router';
import { environment } from 'environments/environment';
import { filter } from 'rxjs/operators';

type FaviconOptions = 'favicon.ico' | 'assets/icons/favicon-development.png';

@Injectable({
  providedIn: 'root',
})
export class BrowserDetailsService {
  private appName = environment.name;
  private favicon: HTMLLinkElement | null = document.querySelector('#favicon');

  //TODO: Set 'theme-color' meta dynamically too

  constructor(
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        let active = this.route;
        while (active.firstChild) {
          active = active.firstChild;
        }
        active.data.subscribe((data: Data) => {
          // Only set if the data is not "true" as that means it will be set later
          if (data['title'] !== true) {
            this.setTitle(data['title']);
          }
        });
      });
  }

  public setFavicon(icon: FaviconOptions): void {
    if (!this.favicon) return;
    this.favicon.href = `/${icon}`;
  }

  public setTitle(newTitle: string | string[] | null = null): void {
    let titleParts = [this.appName];

    if (newTitle) {
      if (typeof newTitle === 'string') titleParts.unshift(newTitle);
      else if (Array.isArray(newTitle))
        titleParts = newTitle.concat(titleParts);
    }

    this.titleService.setTitle(titleParts.join(' | '));
  }
}
