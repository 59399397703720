import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { IconName } from '@awesome.me/kit-62bca205da/icons';
import { filter } from 'rxjs';

export type FabMenuItem = {
  icon?: IconName;
  subIcon?: IconName;
  name: string;
  menu?: Omit<FabMenuItem, 'menu'>[];
  color?: 'primary' | 'accent' | 'basic';
} & (
  | {
      url: string;
      target: string;
      click?: never;
    }
  | {
      url?: never;
      target?: never;
      click: () => void;
    }
);

@Injectable({
  providedIn: 'root',
})
export class FabService {
  public isVisible: boolean = false;
  public fabMenu: FabMenuItem[] = [];
  public icon: IconName = 'pen';
  public tooltipText: string = '';

  public clickAction: () => void = () => {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        let active = this.route;
        while (active.firstChild) {
          active = active.firstChild;
        }
        active.data.subscribe((data: Data) => {
          if (data['fab']) {
            this.show();
          } else {
            this.hide();
          }
        });
      });
  }

  setMenu(menu: FabMenuItem[], clickAction: () => void = () => {}): void {
    this.fabMenu = menu;
    this.clickAction = clickAction;
  }

  setIcon(icon: IconName) {
    this.icon = icon;
  }

  setToolTip(text: string | null) {
    this.tooltipText = text ?? '';
  }

  toggle(): void {
    this.isVisible = !this.isVisible;
  }

  show(): void {
    this.isVisible = true;
  }

  hide(): void {
    this.isVisible = false;
  }
}
