@if (display === "icon") {
  @if (user.avatar) {
    <img
      src="{{ user.avatar }}"
      [matTooltip]="user.name + iconTooltipLineTwo"
      matTooltipPosition="above"
      matTooltipClass="user-tooltip"
      (click)="showUserDetails(user)"
      class="user-image"
      [style.width.px]="iconSize"
    />
  } @else {
    <fa-icon
      [icon]="['fas', 'user']"
      [matTooltip]="user.name + iconTooltipLineTwo"
      matTooltipPosition="above"
      matTooltipClass="user-tooltip"
      (click)="showUserDetails(user)"
      class="user-icon user-icon-single aspect-square max-w-full"
      [style.fontSize.px]="iconSize * 0.625"
      [style.width.px]="iconSize"
    ></fa-icon>
  }
}

@if (display === "card") {
  <mat-card
    (click)="showUserDetails(user)"
    class="cursor-pointer select-none border border-solid border-black border-opacity-10 [&_.mat-mdc-card-header-text]:overflow-hidden"
  >
    <mat-card-header
      class="items-center py-2 [&_.mat-mdc-card-header-text]:flex-grow"
    >
      @if (user.avatar) {
        <img src="{{ user.avatar }}" mat-card-avatar class="mb-0 h-12 w-12" />
      } @else {
        <fa-icon
          [icon]="['fas', 'user']"
          class="mb-0 text-4xl"
          mat-card-avatar
        ></fa-icon>
      }

      <mat-card-title class="text-lg">
        <div class="name">
          @if (isTeamLead) {
            <fa-icon
              [icon]="['fas', 'crown']"
              class="mr-1 text-highlight"
              matTooltip="Team Lead"
              matTooltipPosition="above"
            ></fa-icon>
          }

          {{ user.name }}
        </div>
      </mat-card-title>

      <mat-card-subtitle>
        @if (isTeamLead && teamName) {
          <div class="overflow-hidden text-ellipsis whitespace-nowrap">
            Team Lead of {{ teamName }}
          </div>
        } @else if (user.title) {
          <div class="overflow-hidden text-ellipsis whitespace-nowrap">
            {{ user.title }}
          </div>
        }

        <div class="overflow-hidden text-ellipsis whitespace-nowrap">
          {{ user.email }}
        </div>

        @if (showAutoName) {
          <div
            class="overflow-hidden text-ellipsis whitespace-nowrap font-mono text-sm opacity-60"
          >
            <span
              matTooltip="Auto-generated Unique Name"
              matTooltipPosition="above"
            >
              {{ user.cmm_unique_name }}
            </span>
            |
            <span matTooltip="CMM Unique ID" matTooltipPosition="above">
              {{ user.cmm_id }}
            </span>
          </div>
        }
      </mat-card-subtitle>

      @if (cardActions.length > 0) {
        <mat-card-actions>
          @for (ca of cardActions; track $index) {
            @if (ca.icon) {
              <button
                mat-icon-button
                color="warn"
                (click)="triggerCardAction($event, user, ca.action)"
              >
                <fa-icon [icon]="['fas', ca['icon']]"></fa-icon>
              </button>
            }

            @if (ca.text) {
              <button
                mat-button
                color="warn"
                (click)="triggerCardAction($event, user, ca.action)"
              >
                {{ ca.text }}
              </button>
            }
          }
        </mat-card-actions>
      }
    </mat-card-header>
  </mat-card>
}
